<template>
    <div class="footer">
        <h2 class="footer__text">Fabian Deneuville - {{ year }}</h2>
        <h2 class="footer__link"><a href="mailto:deneuville.fabian@live.fr">Contact</a></h2>
        <!-- <router-link to="/admin" class="footer__link">Admin</router-link> -->
    </div>
</template>

<script>

export default {
    name: 'footer-bottom',
    data(){
        return {
            year: null
        }
    },
    mounted(){
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        this.year = currentYear;
    }
}

</script>