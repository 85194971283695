import Vue from 'vue'
import App from './App.vue'
import Axios from 'axios'
import VueRouter from 'vue-router'
import Routes from '../src/routes'

Vue.config.productionTip = false

Vue.use(VueRouter)

const router = new VueRouter({
  routes: Routes,
  mode: 'history'

})

new Vue({
  render: h => h(App),
  router: router
}).$mount('#app')

Axios.interceptors.response.use(
  function(response){
    return response
  },
  error => {
    if(error.response.data.error && error.response.data.error.name == 'TokenExpiredError'){
      router.push('/')
      localStorage.clear()
    } else {
      return Promise.reject(error)
    }
  })

  