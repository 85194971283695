const stack = [
    {
        name: 'HTML',
        icon: '<i class="devicon-html5-plain-wordmark"></i>'
    },
    {
        name: 'CSS',
        icon: '<i class="devicon-css3-plain-wordmark"></i>'
    },
    {
        name: 'SASS',
        icon: '<i class="devicon-sass-original"></i>'
    },
    {
        name: 'Bootstrap',
        icon: '<i class="devicon-bootstrap-plain"></i>'
    },
    {
        name: 'TypeScript',
        icon: '<i class="devicon-typescript-plain"></i>'
    },
    {
        name: 'React.JS',
        icon: '<i class="devicon-react-original"></i>'
    },
    {
        name: 'REDUX',
        icon: '<i class="devicon-redux-original"></i>'
    },
    {
        name: 'Vue.JS',
        icon: '<i class="devicon-vuejs-plain-wordmark"></i>'
    },
    {
        name: 'Nuxt.JS',
        icon: '<i class="devicon-nuxtjs-plain-wordmark"></i>'
    },
    {
        name: 'Node.JS',
        icon: '<i class="devicon-nodejs-plain-wordmark"></i>'
    },
    {
        name: 'Express',
        icon: '<i class="devicon-express-original-wordmark"></i>'
    },
    {
        name: 'MongoDB',
        icon: '<i class="devicon-mongodb-plain-wordmark"></i>'
    },
    {
        name: 'MySQL',
        icon: '<i class="devicon-mysql-plain-wordmark"></i>'
    },
    {
        name: 'Jest',
        icon: '<i class="devicon-jest-plain"></i>'
    }
]

module.exports = stack;