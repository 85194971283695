<template>

    <div>
        <header-top></header-top>
        <div class="intro">
            <h2 class="intro__heading">Qui suis-je ?</h2>
            <p class="intro__text">Mes expériences préalables de <span class="important">Traffic manager</span> et de <span class="important">responsable marketing</span> me permettent de <span class="important">cerner avec précision</span> les attentes des clients internes et externes, d’<span class="important">analyser les besoins</span> et d’<span class="important">apporter des réponses</span>.</p>
            <hr>
            <p class="intro__text">Je suis particulièrement sensibilisé aux problématiques d'<span class="important">accessibilité</span> numérique et à l'optimisation <span class="important">SEO</span>.</p>
        </div>

        <stack-list></stack-list>

        <div class="projects">
            <h2 class="projects__heading">Mes réalisations</h2>
            <ul class="projects__list">
                <li class="projects__list__item" v-bind:key="index" v-for="(project, index) in projects">
                    <project-item
                    v-bind:title="project.title"
                    v-bind:introduction="project.introduction"
                    v-bind:description="project.description"
                    v-bind:stack="project.stack"
                    v-bind:missions="project.missions"
                    v-bind:repoLink="project.repoLink"
                    v-bind:websiteLink="project.websiteLink"
                    v-bind:youtubeLink="project.youtubeLink"
                    v-bind:imageName="project.imageName"
                    >
                    </project-item>          
                </li>
            </ul>
        </div>

        <devTraining-list></devTraining-list>

        <!-- <contact-form
        @showLoader="showLoader"
        @hideLoader="hideLoader">
        </contact-form> -->

        <loading-spinner
        v-if="loading">
        </loading-spinner>

        <footer-bottom></footer-bottom>
    </div>
    

</template>

<script>

// Datas imports
import Projects from '../assets/datas/projects'

// Components imports
import Header from '../components/header'
import Stack from '../components/stack'
import Project from '../components/project'
import DevTraining from '../components/devTraining'
// import Contact from '../components/contactForm'
import Footer from '../components/footer'
import Loader from '../components/loader'

export default {
    name: 'Portfolio-page',
    data(){
        return {
            projects: Projects,
            loading: false
        }
    }, 
    components: {
        'header-top': Header,
        'stack-list': Stack,
        'project-item': Project,
        'devTraining-list': DevTraining,
        // 'contact-form': Contact,
        'footer-bottom': Footer,
        'loading-spinner': Loader
    },
    methods: {
        showLoader: function(){
            this.loading = true;
        },
        hideLoader: function(){
            this.loading = false;
        }
    }
}

</script>