const devTraining = [
    {
        diploma: 'Développeur Web et Web mobile',
        school: 'OPEN CLASSROOMS',
        dates: "De janvier à juin 2022",
        imageName: "",
        link: "https://openclassrooms.com/fr/paths/185-developpeur-web"
    },
    {
        diploma: 'Développeur d\'application JavaScript/REACT',
        school: 'OPEN CLASSROOMS',
        dates: "De septembre 2022 à mai 2023",
        imageName: "",
        link: "https://openclassrooms.com/fr/paths/516-developpeur-dapplication-javascript-react"
    },

]

module.exports = devTraining;