<template>
    <div class="loader">
        <p class="loader__text"> <span class="loader__text__dot">.</span><span class="loader__text__dot">.</span><span class="loader__text__dot">.</span> Patientez <span class="loader__text__dot">.</span><span class="loader__text__dot">.</span><span class="loader__text__dot">.</span></p>
        <div class="loader__plan">
            <div class="loader__plan__moon"></div>
            <div class="loader__plan__hearth"></div>
        </div>
    </div>
</template>

<script>

export default {
    name:'loading-spinner'
}

</script>