<template>
    <div class="project">
        <div class="project__image">
            <img class="project__image__img" v-bind:src="projectImagePath" v-bind:alt="`Image illustrant le projet ${title}`">
        </div>
        <div class="project__content">
            <h3 class="project__content__introduction">{{ introduction }}</h3>
            <h4 class="project__content__stack__heading">Techno :</h4>
            <ul class="project__content__stack__list">
                <li class="project__content__stack__list__item" v-bind:key="index" v-for="(item, index) in stack">
                    <span>{{ item }}</span>
                </li>
            </ul>
            <h4 class="project__content__missions__heading">Missions :</h4>
            <ul class="project__content__missions__list">
                <li class="project__content__missions__list__item" v-bind:key="index" v-for="(item, index) in missions">
                    <span>{{ item }}</span>
                </li>
            </ul>
        </div>
        <div class="project__links__container">
            <a class=" link-btn" v-bind:href='repoLink' target="_blank">CODE</a>
            <a class=" link-btn" v-if="youtubeLink !='' " v-bind:href='youtubeLink' target="_blank">DEMO</a>
            <a class=" link-btn" v-if="websiteLink !='' " v-bind:href='websiteLink' target="_blank">SITE</a>
        </div>
    </div>
</template>

<script>

export default {
    name: 'project-item',
    data(){
        return {
            projectImagePath: require(`../assets/images/${this.imageName}`)
        }
    },
    props: {
        title: String,
        introduction: String,
        description: String,
        stack: Array,
        missions: Array,
        repoLink: String,
        websiteLink: String,
        youtubeLink: String,
        imageName: String
    }
}


</script>