const projects = [
    {
        title: "OriPhoto",
        introduction: "Un site e-commerce développé avec Nuxt.JS",
        description: "",
        stack: [
            'Nuxt.js',
            'Vuex',
            'Sass',
            'SSR',
            'Heroku'
        ],
        missions: [
            'Optimiser le SEO d\'une application Vue grâce au SSR',
            'Gérer le state d\'une application Vue grâce à Vuex',
            'Communiquer avec une API',
            'Déployer une application Nuxt sur Heroku',
            'Implémenter l\'envoi de mails automatiques grâce à emailjs'

        ],
        repoLink: "https://github.com/fabiandeneuville/oriPhoto",
        youtubeLink: "",
        websiteLink: "https://oriphoto.herokuapp.com/",
        imageName: "oriPhoto.webp"
    },
    {
        title: "Vue Weather",
        introduction: "Un application météo",
        description: "",
        stack: [
            'Vue.js',
            'Vuex',
            'Sass'
        ],
        missions: [
            'Gérer le state d\'une application Vue grâce à Vuex',
            'Communiquer avec une API grâce à Axios',
            'Déployer une application Vue sur Github Pages'

        ],
        repoLink: "https://github.com/fabiandeneuville/vue-weather",
        youtubeLink: "",
        websiteLink: "https://fabiandeneuville.github.io/vue-weather/",
        imageName: "vueWeather.webp"
    },
    {
        title: "Password Strength Checker",
        introduction: "Un programme qui vérifie la solidité d'un mot de passe",
        description: "",
        stack: [
            'TypeScript',
            'Animations CSS',
            'Regex'
        ],
        missions: [
            'Créer des animations CSS',
            'Conditionner le rendu d\'une page selon la valeur de variables',
            'Améliorer la fiabilité du code grâce à TypeScript',
            'Utiliser des expressions réguilières en JavaScript'
        ],
        repoLink: "https://github.com/fabiandeneuville/passwordStrengthChecker",
        youtubeLink: "",
        websiteLink: "https://fabiandeneuville.github.io/passwordStrengthChecker/",
        imageName: "passwordCheck.webp"
    },
    {
        title: "En Roue Libre",
        introduction: "Une landing page produit dynamique pour un vendeur de vélos",
        description: "",
        stack: [
            'HTML',
            'CSS', 
            'JavaScript',
            'TypeScript',
            'Accessibilité'
        ],
        missions: [
            'Intégrer une landing page dynamique',
            'Assurer l\'accessibilité d\'une page web grâce aux bonnes pratiques',
            'Améliorer la fiabilité du code grâce à TypeScript'
        ],
        repoLink: "https://github.com/fabiandeneuville/EnRoueLibre",
        youtubeLink: "",
        websiteLink: "https://fabiandeneuville.github.io/EnRoueLibre/",
        imageName: "en_roue_libre_banner.webp"
    },
    {
        title: "Tahiti Voyages",
        introduction: "Une landing page dynamique pour un voyagiste spécialiste de la Polynésie",
        description: "",
        stack: [
            'HTML',
            'CSS', 
            'JavaScript',
            'TypeScript',
            'Accessibilité'
        ],
        missions: [
            'Intégrer une landing page dynamique',
            'Assurer l\'accessibilité d\'une page web grâce aux bonnes pratiques',
            'Améliorer la fiabilité du code grâce à TypeScript'
        ],
        repoLink: "https://github.com/fabiandeneuville/tahitiVoyages",
        youtubeLink: "",
        websiteLink: "https://fabiandeneuville.github.io/tahitiVoyages/",
        imageName: "tahiti-voyages.webp"
    },
    {
        title: "Netflix Clone",
        introduction: "Un clone de la page d'accueil de Netflix",
        description: "",
        stack: [
            'HTML',
            'CSS', 
            'JavaScript',
            'TypeScript'
        ],
        missions: [
            'Intégrer une page web selon un modèle ou une maquete',
            'Améliorer la fiabilité du code grâce à TypeScript'
        ],
        repoLink: "https://github.com/fabiandeneuville/netflix-clone",
        youtubeLink: "",
        websiteLink: "https://fabiandeneuville.github.io/netflix-clone/",
        imageName: "netflix.webp"
    },
    {
        title: "Chronophage",
        introduction: "Une montre analogique",
        description: "",
        stack: [
            'HTML',
            'CSS', 
            'JavaScript'
        ],
        missions: [
            'Conditionner le style et l\'affichage selon la valeur de variables',
            'Dynamiser une page web grâce aux animations CSS'
        ],
        repoLink: "https://github.com/fabiandeneuville/chronophage",
        youtubeLink: "",
        websiteLink: "https://fabiandeneuville.github.io/chronophage/",
        imageName: "chronophage.webp"
    },
    {
        title: "Roman Numerals",
        introduction: "Un simple programme pour convertir un nombre en chiffres romains et des chiffres romains en nombre",
        description: "",
        stack: [
            'Jest', 
            'JavaScript'
        ],
        missions: [
            'Implémenter des tests unitaires avec Jest',
            'Interagir avec un utilisateur grâce au terminal '
        ],
        repoLink: "https://github.com/fabiandeneuville/romanNumerals",
        youtubeLink: "",
        websiteLink: "",
        imageName: "romanNumerals.webp"
    },
    {
        title: "Groupomania",
        introduction: "Création d'un réseau social d'entreprise",
        description: "Projet 7 du parcours Développeur Web - Open Classrooms",
        stack: [
            'Node.js', 
            'Express', 
            'MySQL', 
            'Vue.js', 
            'Sass'],
        missions: [
            'Authentifier un utilisateur et maintenir sa session',
            'Gérer un stockage de données à l\'aide de SQL',
            'Implémenter un stockage de données sécurisé en utilisant SQL',
            'Personnaliser le contenu envoyé à un client web'
        ],
        repoLink: "https://github.com/fabiandeneuville/FabianDeneuville_7_28032022",
        youtubeLink: "https://youtu.be/kclOCwaHcbs",
        websiteLink: "",
        imageName: "groupomania-logo.webp"
    },
    {
        title: "Piiquante", 
        introduction: "Construction d'une API sécurisée pour une application d'avis gastronomiques",
        description: "Projet 6 du parcours Développeur Web - Open Classrooms",
        stack: [
            'Node.js',
            'Express',
            'MongoDB'
        ], 
        missions: [
            'Authentifier un utilisateur',
            'Implémenter un modèle logique de données conformément à la réglementation (RGPD, OWASP)',
            'Mettre en oeuvre des opérations CRUD de manière sécurisée',
            "Stocker des données de manière sécurisée"
        ],
        repoLink: "https://github.com/fabiandeneuville/FabianDeneuville_6_14032022",
        youtubeLink: "",
        websiteLink: "",
        imageName: "piiquante-logo.webp"

    },
    {
        title: "Kanap",
        introduction: "Constuction d'un site e-commerce en JavaScript",
        description: "Projet 5 du parcours Développeur Web - Open Classrooms",
        stack: [
            'Vanilla JavaScript'
        ],
        missions: [
            'Valider des données issues de sources externes',
            'Gérer des événements JavaScript',
            'Créer un plan de test pour une application',
            'Interagir avec un web service grâce à JavaScript'
        ],
        repoLink: "https://github.com/fabiandeneuville/FabianDeneuville_5_04022022",
        youtubeLink: "",
        websiteLink: "",
        imageName: "kanap-logo.webp"
    },
    {
        title:"La Chouette Agence",
        introduction: "Optimisation d'un site web existant",
        description: "Projet 4 du parcours Développeur Web - Open Classrooms",
        stack: [
            'SEO', 
            'Accessibilité',
            'Performances'
        ],
        missions: [
            'Écrire un code HTML et CSS maintenable',
            'Assurer l\'accessibilité d\'un site web en suivant les recommandations WCAG',
            'Optimiser le référencement d\'un site web',
            'Réaliser une recherche des bonnes pratiques en développement web',
            'Optimiser la taille et la vitesse d’un site web'
        ],
        repoLink: "https://github.com/fabiandeneuville/FabianDeneuville_4_27012022",
        youtubeLink: "",
        websiteLink: "https://fabiandeneuville.github.io/FabianDeneuville_4_27012022/",
        imageName: "la-chouette-agence-logo.webp"
    },
    {
        title: "Ohmyfood",
        introduction: "Dynamiser une page web avec des animations CSS",
        description: "Projet 3 du parcours Développeur Web - Open Classrooms",
        stack: [
            'HTML',
            'CSS', 
            'SASS',
        ],
        missions: [
            'Mettre en place son environnement Front-end',
            'Mettre en place une structure de navigation pour un site web',
            'Utiliser un système de gestion de versions pour le suivi du projet et son hébergement',
            'Assurer la cohérence graphique d\'un site web',
            'Mettre en œuvre des effets CSS graphiques avancés'
        ],
        repoLink: "https://github.com/fabiandeneuville/FabianDeneuville_3_11012022",
        youtubeLink: "",
        websiteLink: "https://fabiandeneuville.github.io/FabianDeneuville_3_11012022/",
        imageName: "ohmyfood-logo.webp"
    },
    {
        title: "Booki",
        introduction: "Transformer une maquette en site web avec HTML & CSS",
        description: "Projet 2 du parcours Développeur Web - Open Classrooms",
        stack: [
            'HTML',
            'CSS'
        ],
        missions: [
            'Intégrer du contenu conformément à une maquette',
            'Implémenter une interface responsive'
        ],
        repoLink: "https://github.com/fabiandeneuville/FabianDeneuville_2_04012022",
        youtubeLink: "",
        websiteLink: "https://fabiandeneuville.github.io/FabianDeneuville_2_04012022/",
        imageName: "booki-logo.webp"
    }
    
]

module.exports = projects;