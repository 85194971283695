<template>
    <div class="devTraining">
        <h2 class="devTraining__heading">Ma Formation</h2>
        <ul class="devTraining__list">
            <li class="devTraining__list__item" v-bind:key="index" v-for="(training, index) in DevTraining">
                <a class="devTraining__list__item__link" v-bind:href="training.link" target="_blank">
                    <h3 class="devTraining__list__item__link__diploma">{{ training.diploma }}</h3>
                    <p class="devTraining__list__item__link__school">{{ training.school }}</p>
                    <p class="devTraining__list__item__link__date">{{ training.dates }}</p>
                </a>
            </li>
        </ul>
    </div>    
</template>

<script>

import DevTraining from '../assets/datas/devTraining';

export default {
    name: 'devTraining-list',
    data(){
        return {
            DevTraining: DevTraining
        }
    } 
}

</script>