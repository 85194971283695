<template>
    <div class="stack">
        <h2 class="stack__heading">Ma Stack</h2>
        <ul class="stack__list">
            <li class="stack__list__item" v-bind:key="index" v-for="(stack, index) in stack">
                <span v-html="stack.icon" v-bind:aria-label="stack.name"></span>
            </li>
        </ul>
    </div>    
</template>

<script>

import Stack from '../assets/datas/stack';

export default {
    name: 'stack-list',
    data(){
        return {
            stack: Stack
        }
    } 
}

</script>