import Portfolio from '../src/views/portfolio'
// import Login from '../src/views/login'
// import Dashboard from '../src/views/dashboard'

export default [
    {
        path: '/',
        component: Portfolio
    },
    // {
    //     path: '/admin',
    //     component: Login,
    //     beforeEnter(route, redirect, next){
    //         redirect = '/dashboard'
    //         if(localStorage.getItem('user') != null){
    //             next(redirect)
    //         } else {
    //             next()
    //         }
    //     }
    // },
    // {
    //     path: '/dashboard',
    //     component: Dashboard,
    //     beforeEnter(route, redirect, next){
    //         redirect = '/'
    //         if(localStorage.getItem('user') === null){
    //             next(redirect)
    //         } else {
    //             next()
    //         }
    //     }
    // },
    {
        path: '*',
        component: Portfolio
    }
]