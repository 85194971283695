<template>
    <div class="header">
        <div class="header__image">
            <img class="header__image__img" v-bind:src="profilePicturePath" v-bind:alt="`Photo de ${name}`">
        </div>
        <div class="header__text glitch-bloc">
            <p class="invisible-text">Fabian Deneuville</p>
            <p class="glitchedAnim">Fabian Deneuville</p>
            <p class="glitchedAnim">Fabian Deneuville</p>
            <p class="glitchedAnim">Fabian Deneuville</p>
        </div>
        <h1 class="header__heading">Développeur Web Front-End</h1>
        <nav class="header__nav">
            <ul class="header__nav__list">
                <!-- <li class="header__nav__list__item">
                    <a href="#contact" rel="noopener" aria-label="Contactez-moi">
                        <i class="fa-solid fa-envelope"></i>
                    </a>
                </li> -->
                <li class="header__nav__list__item">
                    <a v-bind:href="githubLink" target="_blank" rel="noopener" aria-label="Voir mon profil Github">
                        <i class="devicon-github-original-wordmark"></i>
                    </a>
                </li>
                <li class="header__nav__list__item">
                    <a v-bind:href="linkedInLink" target="_blank" rel="noopener" aria-label="Voir mon profil LinkedIn">
                        <i class="devicon-linkedin-plain"></i>
                    </a>
                </li>
                <li class="header__nav__list__item">
                    <a v-bind:href="codepenLink" target="_blank" rel="noopener" aria-label="Voir mon profil CodePen">
                        <i class="devicon-codepen-plain"></i>
                    </a>
                </li>
                <li class="header__nav__list__item">
                    <a href="cv_deneuville_fabian.pdf" target="_blank" rel="noopener" download="cv_deneuville_fabian.pdf" aria-label="Télécharger mon CV">
                        <i class="fa-solid fa-file-pdf"></i>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>

export default {
    name: 'header-top',
    data(){
        return {
            profilePicturePath: require('../assets/images/fabian.webp'),
            name: 'Fabian Deneuville',
            linkedInLink:'https://www.linkedin.com/in/fabian-deneuville/',
            githubLink:'https://github.com/fabiandeneuville',
            codepenLink:'https://codepen.io/fabiandeneuville'
        }
    }
}

</script>